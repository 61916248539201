.concerns-title {
    text-align: center;
    font-weight: bolder;
    font-size: 50px;
    width: 100%;
    font-weight: bold;
    margin: 40px 0px 40px 0px;
}

@media only screen and (min-width: 769px) {
    .concerns-issues {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 120px;
        margin-right: 120px;
        background-color: white;
        padding: 30px;
    }

    iframe {
        margin-top: 30px;
        width: 420px;
        height: 236px;
    }

    .video-inner-container {
        max-width: 420px;
    }

    .concerns-reading {
        display: flex;
        flex-direction: row;
        margin-right: 120px;
        margin-top: 15px;
        justify-content: center;
    }

    .concerns-further-reading {
        margin-top: 15px;
        margin-left: 120px;
        background-color: white;
        padding: 30px;
    }

    .book-image {
        margin-top: 15px;
        background-size: contain;
        width: 262px;
        height: 400px;
        background-repeat: no-repeat;
        margin-left: 30px;
    }

    .concerns-title-secondary {
        font-size: 24px;
        font-weight: 800;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        margin-top: 30px;
    }

    .concerns-heading-big {
        font-size: 24px;
        font-weight: 800;
        width: fit-content;
    }
}

@media only screen and (max-width: 768px) {
    .concerns-issues {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        padding: 30px;
    }

    iframe {
        margin-top: 30px;
        width: 340px;
        height: 190px;
    }

    .video-inner-container {
        max-width: 340px;
    }

    .concerns-reading {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-top: 30px;
    }

    .concerns-further-reading {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 20px;
        background-color: white;
        padding: 30px;
    }

    .book-image {
        margin-top: 30px;
        background-size: contain;
        width: 262px;
        height: 400px;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
    }

    .concerns-title-secondary {
        font-weight: 800;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        margin-top: 30px;
        text-align: center;
        font-size: x-large;
    }

    .concerns-heading-big {
        font-weight: 800;
        width: fit-content;
        text-align: center;
        font-size: x-large;
    }
}

.video-link-container {
    height: fit-content;
    font-weight: 800px;
}

.concerns-heading {
    font-size: 18px;
    font-weight: 600;
    width: fit-content;
    line-height: 30px;
}

.concerns-link {
    text-underline-offset: 1px;
    text-decoration-thickness: 1px;
    color: #2ecc71;
    font-size: 18px;
    font-weight: 600;
    width: fit-content;
    line-height: 30px;
}

.concerns-link-div {
    font-weight: 600;
    width: fit-content;
    margin-top: 15px;
}

.concerns-note {
    font-size: small;
    font-weight: 700;
    width: fit-content;
    font-style: italic;
}

.concerns-body-normal {
    margin-top: 15px;
    width: fit-content;
    line-height: 27px;
}

.video-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
}

.video-container-inner {
    margin-left: 15px;
    margin-right: 15px;
}

.video-summary {
    margin-top: 10px;
    width: fit-content;
    line-height: 27px;
}
