@media only screen and (min-width: 769px) {
    .footer-top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color: black;
    }

    .footer-bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color: #2ecc71;
    }

    a.email-us-button {
        text-underline-offset: 3px;
        color: white;
        text-decoration-color: #ff3b3b;
        padding: 10px;
        white-space: nowrap;
        text-decoration-thickness: 2px;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 40px;
        margin-left: 20px;
        background-color: black;
        font-weight: 600;
    }

    a.email-us-button:hover {
        background-color: #ff3b3b;
        text-decoration-color: black;
    }

    .footer-blurb {
        color: white;
        background-color: #2ecc71;
        min-width: 290px;
        margin: 40px 0px 40px 40px;
        font-style: italic;
        text-align: center;
        font-family: Georgia, 'Times New Roman', Times, serif;
        line-height: 35px;
        font-size: 24px;
    }

    .footer-section {
        font-size: large;
        color: white;
        background-color: black;
        margin: 30px;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: left;
        flex: 1;
    }
}

@media only screen and (max-width: 768px) {
    .footer-top {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        background-color: black;
        padding-bottom: 40px;
    }

    .footer-bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #2ecc71;
    }

    a.email-us-button {
        text-underline-offset: 3px;
        color: white;
        text-decoration-color: #ff3b3b;
        padding: 10px;
        white-space: nowrap;
        text-decoration-thickness: 2px;
        margin-top: auto;
        margin-bottom: 40px;
        margin-right: 40px;
        margin-left: 20px;
        background-color: black;
        font-weight: 600;
    }

    a.email-us-button:hover {
        background-color: #ff3b3b;
        text-decoration-color: black;
    }

    .footer-blurb {
        font-size: x-large;
        color: white;
        background-color: #2ecc71;
        min-width: 290px;
        margin: 30px;
        font-style: italic;
        text-align: center;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

    .footer-section {
        font-size: large;
        color: white;
        background-color: black;
        min-width: 290px;
        margin: 30px;
        margin-top: 40px;
        margin-bottom: 10px;
        text-align: left;
    }
}

.footer-section-normal {
    margin-top: 20px;
    font-weight: 300;
    line-height: 27px;
}

.footer-section-emphasis {
    margin-top: 20px;
    font-weight: 600;
    line-height: 27px;
}

.footer-section-title {
    color: white;
    background-color: black;
    text-align: left;
    font-size: x-large;
    font-weight: 800;
}

.footer-section-hr {
    font-size: x-large;
    background-color: #2ecc71;
    text-align: left;
    height: 2px;
    width: 100px;
    margin-top: 20px;
}

.footer-link {
    text-underline-offset: 3px;
    color: #2ecc71;
    text-decoration-thickness: 2px;
    font-weight: 600;
}

.no-edits {
    text-decoration: underline;
}
