.navbar-container {
    background-color: black;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.navbar-button-unselected {
    height: 38px;
    width: 110px;
    background-color: black;
    color: white;
    border: none;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.navbar-button-selected {
    height: 38px;
    width: 110px;
    background-color: #2ecc71;
    color: black;
    border: none;
    text-align: center;
    font-weight: 600;
}
