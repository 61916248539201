@media only screen and (min-width: 769px) {
    .quadrant {
        border-style: solid;
        display: inline-block;
        height: 120px;
        width: 120px;
    }

    .councillor-image {
        top: -40px;
        position: relative;
        background-size: contain;
        width: 240px;
        height: 240px;
        border-radius: 100%;
        border-width: 0;
        right: 0px;
        background-clip: content-box;
        margin-left: auto;
        float: right;
        background-position: center;
        background-size: cover;
    }

    .councillor-body {
        background-color: white;
        margin-left: 120px;
        margin-right: 120px;
        margin-top: 40px;
        padding-top: 40px;
        padding-bottom: 20px;
        white-space: pre-wrap;
    }

    .councillor-share-button {
        width: 280px;
        background-color: #2ecc71;
        color: white;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        margin-top: 40px;
        text-underline-offset: 3px;
        text-decoration-color: black;
        text-decoration-thickness: 2px;
    }

    .councillor-answers-container {
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .quadrant {
        border-style: solid;
        display: inline-block;
        height: 110px;
        width: 110px;
    }

    .councillor-image {
        background-size: contain;
        width: 220px;
        height: 220px;
        border-radius: 100%;
        border-width: 0;
        background-clip: content-box;
        margin-top: -20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        position: relative;
        background-position: center;
        background-size: cover;
    }

    .councillor-body {
        background-color: white;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        white-space: pre-wrap;
    }

    .councillor-share-button {
        width: 280px;
        background-color: #2ecc71;
        color: white;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        text-underline-offset: 3px;
        text-decoration-color: black;
        text-decoration-thickness: 2px;
    }

    .councillor-answers-container {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 100px;
    }
}

.councillor-row-left-aligned {
    display: flex;
    justify-content: left;
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 30px 0;
}

.councillor-name {
    text-align: center;
    font-weight: bolder;
    font-size: 50px;
    width: 100%;
    font-weight: bold;
    margin: 40px 0px 10px 0px;
}

.councillor-term {
    font-family: 'Georgia';
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.councillor-seat-unsafe {
    color: white;
    padding: 3px 7px 4px 6px;
    background-color: rgb(237, 203, 13);
    text-transform: uppercase;
    font-size: x-small;
    font-weight: 800;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.councillor-seat-safe {
    color: white;
    padding: 3px 7px 2px 6px;
    background-color: #8e8e8e;
    text-transform: uppercase;
    font-size: x-small;
    font-weight: 800;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.councillor-body-heading {
    margin-top: 40px;
    font-size: large;
    font-weight: 600;
}

.councillor-body-note {
    font-size: x-small;
    padding-top: 10px;
    line-height: 18px;
}

.councillor-body-text {
    padding-top: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    line-height: 27px;
    font-style: italic;
    font-size: 18px;
    margin: 0 0 30px 0;
    line-height: 30px;
}

.councillor-body-hr {
    margin-top: 20px;
    background-color: #cecece;
    width: 120px;
    height: 2px;
}

.councillor-contact-message-container {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
}

.councillor-contact-message {
    word-wrap: break-word;
    text-align: center;
    font-size: small;
    font-weight: 600;
}

.councillor-email-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 40px;
    width: 160px;
    padding: 10px;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: 600;
    text-underline-offset: 3px;
    text-decoration-color: #ff3b3b;
    text-decoration-thickness: 2px;
}

.councillor-email-container {
    display: flex;
    flex-flow: column;
}

a.councillor-share-button:hover {
    background-color: black;
    text-decoration-color: #2ecc71;
}

a.councillor-email-button:hover {
    background-color: #ff3b3b;
    text-decoration-color: black;
}
