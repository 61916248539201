.unknown {
    border-color: #8e8e8e;
    color: #8e8e8e;
    font-weight: bold;
}

.not-applicable {
    border-color: #0000;
    color: #0000;
    font-weight: bold;
}

.none {
    border-color: black;
    color: black;
    font-weight: bold;
}

.good {
    border-color: #2ecc71;
    color: #2ecc71;
    font-weight: bold;
}

.bad {
    border-color: #ff3b3b;
    color: #ff3b3b;
    font-weight: bold;
}

.top-left {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 100%;
    border-width: 20px 0px 0px 20px;
    margin-left: -20px;
    margin-top: -20px;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 100%;
    border-width: 20px 20px 0px 0px;
    margin-right: -20px;
    margin-top: -20px;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 100%;
    border-width: 0px 20px 20px 0px;
    margin-right: -20px;
    margin-bottom: -20px;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 100%;
    border-width: 0px 0px 20px 20px;
    margin-left: -20px;
    margin-bottom: -20px;
}

@media only screen and (min-width: 769px) {
    .portrait {
        padding-left: 40px;
        padding-right: 40px;
        background-color: white;
        width: 340px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 40px;
    }

    .portrait-image {
        position: relative;
        background-size: contain;
        width: 240px;
        height: 240px;
        border-radius: 100%;
        border-width: 0;
        background-clip: content-box;
        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-size: cover;
    }

    .quadrant {
        border-style: solid;
        display: inline-block;
        height: 120px;
        width: 120px;
    }

    .bottom-middle-container {
        width: 340px;
        display: flex;
        flex-flow: column;
    }

    .contact-message {
        word-wrap: break-word;
        text-align: center;
        font-size: small;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .portrait-table-with-tiny-gap {
        margin-top: 5px;
    }

    .portrait-table-with-vsmall-gap {
        margin-top: 10px;
    }

    .portrait-table-with-small-gap {
        margin-top: 20px;
    }

    .portrait-table-with-large-gap {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .portrait {
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;
        width: 300px;
        padding-bottom: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .portrait-image {
        position: relative;
        background-size: contain;
        width: 220px;
        height: 220px;
        border-radius: 100%;
        border-width: 0;
        background-clip: content-box;
        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-size: cover;
    }

    .quadrant {
        border-style: solid;
        display: inline-block;
        height: 110px;
        width: 110px;
    }

    .bottom-middle-container {
        width: 300px;
        display: flex;
        flex-flow: column;
    }

    .contact-message {
        word-wrap: break-word;
        text-align: center;
        font-size: small;
        font-weight: 600;
        width: 340px;
        margin-bottom: 10px;
    }

    .portrait-table-with-tiny-gap {
        margin-top: 10px;
    }

    .portrait-table-with-vsmall-gap {
        margin-top: 10px;
    }

    .portrait-table-with-small-gap {
        margin-top: 20px;
    }

    .portrait-table-with-large-gap {
        margin-top: 40px;
    }
}

.email-button {
    background-color: black;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    text-underline-offset: 3px;
    text-decoration-color: #ff3b3b;
    text-decoration-thickness: 2px;
}

.share-button {
    background-color: #2ecc71;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    text-underline-offset: 3px;
    text-decoration-color: black;
    text-decoration-thickness: 2px;
}

.portrait-row-left-aligned {
    display: flex;
    justify-content: left;
    font-size: 14px;
    line-height: 27px;
}

.portrait-icon {
    position: relative;
    background-size: contain;
    width: 18px;
    height: 18px;
    background-clip: content-box;
    margin-right: 5px;
    margin-top: 4px;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 18px;
    min-height: 18px;
}

.no-link-styling {
    color: black;
}

.portrait-name {
    font-weight: bolder;
    font-size: 24px;
    line-height: 30px;
}

.portrait-row-center-aligned {
    display: flex;
    justify-content: center;
}

.portrait-row-term-expires {
    font-family: 'Georgia';
}

.portrait-row-term-expires-unsafe {
    color: white;
    padding: 3px 7px 4px 6px;
    background-color: rgb(237, 203, 13);
    text-transform: uppercase;
    font-size: x-small;
    font-weight: 800;
}

.portrait-row-term-expires-safe {
    color: white;
    padding: 3px 7px 2px 6px;
    background-color: #8e8e8e;
    text-transform: uppercase;
    font-size: x-small;
    font-weight: 800;
}

.response-or-answer {
    font-weight: 600;
    font-size: 18px;
}

.YES {
    margin-left: 5px;
    color: #2ecc71;
    font-weight: bold;
}

.NO {
    margin-left: 5px;
    color: #ff3b3b;
    font-weight: bold;
}

.PASSIVE {
    margin-left: 5px;
    color: #8e8e8e;
    font-weight: bold;
}

.hr {
    background-color: #cecece;
    width: 120px;
    height: 2px;
}

a.email-button:hover {
    background-color: #ff3b3b;
    text-decoration-color: black;
}

a.share-button:hover {
    background-color: black;
    text-decoration-color: #2ecc71;
}
