@media only screen and (min-width: 769px) {
    .whoarewe-top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color: white;
        margin-top: 60px;
    }

    .whoarewe-section-left {
        font-size: large;
        color: black;
        background-color: white;
        flex: 1;
        text-align: left;
    }

    .whoarewe-section-center {
        font-size: large;
        color: black;
        background-color: white;
        flex: 1;
        text-align: -webkit-center;
    }

    .whoarewe-section-text {
        margin: 30px 30px 30px 30px;
        min-width: 220px;
    }

    .icon-image {
        background-size: contain;
        height: 220px;
        background-repeat: no-repeat;
        min-width: 220px;
        min-height: 300px;
        background-position: center;
    }

    .us-image {
        background-size: contain;
        height: 220px;
        background-repeat: no-repeat;
        min-width: 220px;
        min-height: 300px;
        background-position: center right;
    }
}

@media only screen and (max-width: 768px) {
    .whoarewe-top {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: white;
        margin-top: 30px;
    }

    .whoarewe-section-left {
        font-size: large;
        color: black;
        background-color: white;
        margin: 30px;
        margin-top: 0px;
        margin-bottom: 10px;
        text-align: left;
    }

    .whoarewe-section-center {
        font-size: large;
        color: black;
        background-color: white;
        margin: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }

    .whoarewe-section-text {
        padding: 0px 30px 0px 0px;
    }

    .icon-image {
        background-size: contain;
        width: 320px;
        height: 250px;
        background-repeat: no-repeat;
        text-align: -wecenter;
        margin-left: auto;
        margin-right: auto;
    }

    .us-image {
        background-size: contain;
        height: 220px;
        background-repeat: no-repeat;
        min-width: 220px;
        min-height: 300px;
        background-position: center;
        margin-bottom: 30px;
    }
}

.whoarewe-section-normal {
    margin-top: 20px;
    font-weight: 300;
    line-height: 27px;
}

.whoarewe-section-emphasis {
    margin-top: 20px;
    font-weight: 600;
    line-height: 27px;
}

.whoarewe-section-title {
    color: black;
    background-color: white;
    text-align: left;
    font-size: x-large;
    font-weight: 800;
}
