.portrait-frame {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-content: space-evenly;
    width: 100%;
    justify-content: space-evenly;
}

.portrait-frame-empty {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: x-large;
    font-weight: 600;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-content: space-evenly;
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
}
